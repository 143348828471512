<template>
    <div class="IndustryTrendsList">
        <el-page-header @back="backPrivious" content="行业动态"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>行业动态</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="media f-c-e mt-30">
            <el-popover placement="bottom" width="360" trigger="hover">
                <div class="popoverBox f-cl-c">
                    <img v-if="MediumStateInfo.homeImg"
                        style="border-radius: 50%;width: 55px;height: 55px;margin-bottom: 20px;" class="img"
                        :src="MediumStateInfo.homeImg" alt="">
                    <img v-else class="logo" src="@/assets/Community/IndustryTrends/logo.png" alt="">
                    <div style="font-size: 22px !important;color: #333;">{{ MediumStateInfo.mediumName }}</div>
                    <div class="fs-22 mt-10 paddingTB-5 paddingLR-15 borderRa-10" :class="MediumStateInfo.mediumClass"
                        style="font-size: 22px !important;">{{ MediumStateInfo.mediumStateText }}</div>

                    <div class="f-cl-c mt-40">
                        <div class="f-c-c w-300 paddingTB-10" style="border-bottom: 1px solid #E0E1E3;cursor: pointer;"
                            @click="toShip('media')">
                            <img class="icon" src="@/assets/Community/IndustryTrends/mediaIcon.png" alt="">
                            <div class="w-60 ml-20">媒体认证</div>
                        </div>
                        <div class="f-c-c w-300 paddingTB-10" style="border-bottom: 1px solid #E0E1E3;cursor: pointer;"
                            @click="toShip('infoList')">
                            <img class="icon" src="@/assets/Community/IndustryTrends/newsIcon.png" alt="">
                            <div class="w-60 ml-20">消息</div>
                        </div>
                        <div class="f-c-c w-300 paddingTB-10" style="border-bottom: 1px solid #E0E1E3;cursor: pointer;"
                            @click="toShip('publish')">
                            <img class="icon" src="@/assets/Community/IndustryTrends/fabuIcon.png" alt="">
                            <div class="w-60 ml-20">发布</div>
                        </div>
                    </div>
                </div>
                <div slot="reference">
                    <img class="mediaBtn" src="@/assets/Community/IndustryTrends/media.png" alt="">
                </div>
            </el-popover>

        </div>
        <div class="list" v-if="IndustryTrendsList && IndustryTrendsList.length">
            <div class="Item flex" v-for="(item, index) of IndustryTrendsList" :key="index"
                @click="toIndustryTrendsDetail(item.postId)">
                <div class="img">
                    <img class="img" :src="item.userAvatarUrl" alt="">
                </div>
                <div class="info">
                    <div class="flex mb-30">
                        <div class="userName">{{ item.userName }}</div>
                        <div class="createDate">{{ item.createDate }}</div>
                    </div>
                    <div class="postHead">{{ item.postHead }}</div>
                    <div class="previewContent">{{ item.previewContent }}</div>
                    <div v-if="item.mobject" class="mobject">
                        <img :src="item.mobject" alt="">
                    </div>
                    <div class="comments f-c">
                        <img src="@/assets/Community/IndustryTrends/comment.png" alt="">
                        {{ item.comments }}条评论
                    </div>
                </div>

            </div>
        </div>

        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
    </div>
</template>

<script>


import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';

export default {
    name: 'IndustryTrendsList',
    mixins: [PaginationRetention],
    data() {
        return {
            IndustryTrendsList: [],
            MediumStateInfo: {},
        }
    },
    created() {
        this.findForumMediumState();
    },
    methods: {
        getDate() {
            this.forumNewsArticleList(this.paging, this.pagingSize,);
        },
        // 获取帖子列表
        forumNewsArticleList(page = this.page, size = this.size) {
            let that = this;
            that.$http.forumNewsArticleList({
                postUserId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    that.IndustryTrendsList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;

                }
            })
        },
        //  跳转到帖子详情
        toIndustryTrendsDetail(postId) {
            // console.log(postId)
            this.$router.push({
                name: 'IndustryTrendsDetail', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        postId,
                    }))
                }
            })
        },
        // 获取媒体信息
        findForumMediumState() {
            let that = this;
            that.$http.findForumMediumState({
                userId: parseInt(that.$store.state.userId)
            }).then(res => {
                if (res.code == 200) {
                    let {
                        mediumState
                    } = res.data,
                        MediumStateInfo = res.data;
                    switch (mediumState) {
                        case '00': //无媒体信息
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '未认证'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                        case '10':
                            that.AuthenticationList = that.CertifiedAuthenticationList
                            break;
                        case '20': //审核申请
                        case '21': //审核申请失败
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '申请不通过'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                        case '30': //审核修改
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '认证审核中'
                            MediumStateInfo.mediumClass = 'bg-FCDAB2 col-F48400'
                            break;

                        case '31': //审核修改失败
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '审核不通过'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                    }
                    that.MediumStateInfo = MediumStateInfo;
                    that.$setStorage('MediumStateInfo', MediumStateInfo);

                }
            })
        },
        // 媒体
        toShip(val) {
            let that = this,
                { mediumState } = that.MediumStateInfo;
            switch (val) {
                case 'media':
                    switch (mediumState) {
                        case '00':
                            that.$router.push({
                                name: 'MediaApplication'
                            });
                            break;
                        case '10':
                            that.$router.push({
                                name: 'MediaInfo'
                            });
                            break;
                        case '30':
                            that.$router.push({
                                name: 'MediaInfo'
                            });
                            break;
                        case '31':
                            that.$router.push({
                                name: 'MediaInfo'
                            });
                            break;
                    }

                    break;
                case 'infoList':
                    console.log('infoList')
                    break;
                case 'publish':
                    that.$router.push({
                        name: 'IndustryTrendsPost'
                    });
                    break;
            }
        }

    },
}

</script>
<style lang='less' scoped>
.IndustryTrendsList {
    position: relative;

    .media {
        // position: absolute;
        // top: 180px;
        // right: 0px;

        .popoverBox {
            padding: 30px;

            .img {
                width: 55px;
                height: 55px;
                margin-bottom: 20px;
                border-radius: 50%;
            }

            .logo {
                width: 55px;
                height: 55px;
                margin-bottom: 20px;
                border-radius: 50%;
            }

            .icon {
                margin-right: 20px;
            }
        }
    }

    .mediaBtn {
        &:hover {
            cursor: pointer;
        }
    }

    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .list {
        margin-top: 30px;

        .Item {
            border-radius: 20px;
            background: #F8F8F8;
            padding: 30px 75px;
            margin-bottom: 20px;
            cursor: pointer;

            .img {
                height: 55px;
                width: 55px;
                border-radius: 50%;
                margin-right: 15px;

            }

            .info {
                .userName {
                    font-size: 20px;
                    color: #333333;
                    line-height: 20px;
                }

                .createDate {
                    margin-left: 10px;
                    font-size: 16px;
                    color: #999999;
                    line-height: 20px;
                }

                .postHead {
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    margin-bottom: 15px;
                }

                .previewContent {
                    font-size: 16px;
                    color: #666666;
                    margin-bottom: 15px;
                }

                .mobject {
                    margin-bottom: 20px;

                    img {
                        border-radius: 20px;
                        height: 100px;
                        width: 100px;
                    }

                }

                .comments {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    font-size: 16px;
                    color: #999999;
                }
            }
        }
    }
}
</style>